body{
    color: #29292e;
}
.fundo{
    background-color: rgb(231, 228, 228);
}
.margem{
    margin: 20px;
}
.iconeG{
    font-size: 2rem;
    margin-top: -5px;
    margin-bottom: -5px;
}