.container{
    height: 100vh;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.card-body{
    background-color: #fff;
}